import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Header from "../components/Header";

const API_URL = "https://backendapi-ez8q.onrender.com/users"; // URL da sua API backend

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #62676b;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 15px;
  margin-top: 10%;
  margin-bottom: 10%;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #ff3300;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;

  &:focus {
    outline: none;
    border-color: #ff3300;
  }
`;

const Select = styled.select`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #ff3300;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #ff3300;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;

  &:hover {
    background-color: #e02d00;
  }
`;

const UserList = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 500px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  button {
    background-color: #ff3300;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #e02d00;
    }
  }
`;

const ContactForm: React.FC = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  // Fetch users from the API
  const fetchUsers = async () => {
    const response = await axios.get(API_URL);
    setUsers(response.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await axios.post(API_URL, formData);
    fetchUsers();
    setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
  };

  // Handle delete user
  const handleDelete = async (id: number) => {
    await axios.delete(`${API_URL}/${id}`);
    fetchUsers();
  };

  return (
    <Container>
      <Header backgroundColor="rgba(50, 57, 63, 1)" />
      <Title>Contato</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Nome"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
        <Input
          type="email"
          placeholder="E-mail"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />
        <Input
          type="tel"
          placeholder="Telefone"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          required
        />
        <Select
          value={formData.subject}
          onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
          required
        >
          <option value="">Assunto</option>
          <option value="consultor">Quero ser um consultor de negócios</option>
          <option value="parceiro">Quero ser um parceiro de negócios</option>
          <option value="cliente">Quero ser um cliente</option>
          <option value="duvidas">Dúvidas</option>
        </Select>
        <Textarea
          placeholder="Mensagem"
          rows={5}
          value={formData.message}
          onChange={(e) => setFormData({ ...formData, message: e.target.value })}
          required
        ></Textarea>
        <Button type="submit">Enviar</Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
