import React from "react";
import styled from "styled-components";
import { COLORS, BREAKPOINTS } from "../constants/style";
import { SocialIcon } from "react-social-icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import logo from "../constants/assets/img/logo.png";

// Estilização
const FooterContainer = styled.footer`
  background-color: #62676b;
  color: ${COLORS.white};
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Ajustado para empilhar conteúdo verticalmente */
  align-items: center;
  gap: 20px;
`;

const Redes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; /* Espaçamento entre os itens */
  margin-bottom: 20px;

  p {
    margin-right: 10px;
    font-size: 0.9rem;
    color: ${COLORS.white};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  p,
  a {
    color: ${COLORS.white};
    margin: 0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const Section2 = styled.div`
  display: flex;
  flex-direction: column;
   justify-content: left;
  gap: 10px;

  p,
  a {
    color: ${COLORS.white};
    margin: 0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const LogoSection = styled(Section)`
  align-items: center;
  text-align: center;

  img {
    max-width: 100px;
    margin-bottom: 10px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid #32393f;
  width: 100%;
  margin: 20px 0;
`;

const BottomBar = styled.div`
  background-color: #505558;
  color: ${COLORS.white};
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9rem;
  width: 100%; 
  padding-bottom: 2%; 

  a {
    color: ${COLORS.white};
    text-decoration: none;
    margin: 0 10px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

// Componente Footer
const Footer: React.FC = () => {
  return (
    <FooterContainer>
      {/* Redes Sociais */}
      <Redes>
        <p>Nossas redes sociais:</p>
        <SocialIcon
          url="https://www.facebook.com/capitales"
          bgColor="white"
          fgColor="black"
          style={{ height: 30, width: 30 }}
        />
        <SocialIcon
          url="https://www.instagram.com/originei/"
          bgColor="white"
          fgColor="black"
          style={{ height: 30, width: 30 }}
        />
        <SocialIcon
          url="https://www.linkedin.com/company/capitalesbr/about/"
          bgColor="white"
          fgColor="black"
          style={{ height: 30, width: 30 }}
        />
      </Redes>
      <div
  style={{
    borderTop: "1px solid #32393F", // Linha vermelha com 1px de espessura
    margin: "0 auto", // Centraliza horizontalmente
    width: "80%", // Define a largura como 80% do contêiner
  }}
></div>

      {/* Conteúdo Principal */}
      <ContentContainer>
{/* Localização */}
        <Section2>
          <p></p>
        </Section2>
        {/* Mapa do Site */}
        <Section>
          <h4>Site Map</h4>
          <a href="/">Home</a>
          <a href="/about">Sobre a Originei</a>
          <a href="/consultor">Consultor</a>
          <a href="/parceiro">Parceiro</a>
          <a href="/contato">Contato</a>
        </Section>

        {/* Logotipo */}
        <LogoSection>
          <img src={logo} alt="Originei Logo" />
          <p>PARTNERS INTERMEDIAÇÃO DE NEGÓCIOS LTDA</p>
          <p>CNPJ: 45.912.887/0001-222</p>
        </LogoSection>
      </ContentContainer>


      {/* Barra Inferior */}
      <BottomBar>
        <p>
          Todos os direitos relativos ao website, marca Originei, Plataforma e
          suas funcionalidades são de propriedade exclusiva da Originei.
        </p>
        <a href="/privacy">Política de privacidade</a>
        <a href="/terms">Termos de Uso</a>
      </BottomBar>
    </FooterContainer>
  );
};

export default Footer;
