import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS, BREAKPOINTS } from "../constants/style";
import Header from "../components/Header";
import fundo from "../constants/assets/img/teste.jpeg";
import hand from '../constants/assets/img/handshakingoriginei.jpeg';
import CustomButton from "../components/CustomButton"; // Mantemos o CustomButton importado
import CustomButton2 from "../components/CustomButton2";
import predio from '../constants/assets/img/loginimg.jpg'

const Predio = styled.img`
    max-width: 50%; 
    align-items:
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
`; 

const Container = styled.div`
  background-color: ${COLORS.background};
  display: flex;
  justify-content: flex-start; 
`;


const Login: React.FC = () => {
    return (
      <Container>
        <Predio src={predio} alt="Originei Logo"/>
      </Container>
    );
  };
  
  export default Login;
  